
import { defineComponent } from 'vue'
import OfferModal from './OfferModal.vue'

export default defineComponent({
  components: { OfferModal },

  props: {
    offer: {
      type: String,
      default: 'subscriptionOffer'
    },
    startActive: {
      type: Boolean,
      default: false
    }
  },
  setup () {
    return {}
  }
})
