<template>
  <offer-modal
    :offer="offer"
    :start-active="startActive"
  >
    <template v-slot:button >
      <slot>
        <button
          class="btn"
          v-bind="$attrs"
        >
          <i class="fas fa-crown"></i>
          {{ $t('action.make_upgrade') }}
        </button>
      </slot>
    </template>
  </offer-modal>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import OfferModal from './OfferModal.vue'

export default defineComponent({
  components: { OfferModal },

  props: {
    offer: {
      type: String,
      default: 'subscriptionOffer'
    },
    startActive: {
      type: Boolean,
      default: false
    }
  },
  setup () {
    return {}
  }
})
</script>

<style scoped>
.fa-crown {
  @apply text-yellow-500 !important;
}
</style>
