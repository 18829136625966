
import { useBreakpoints } from '@/composables/useBreakpoints'
import { computed, defineComponent } from 'vue'
import UpgradeButton from '../freeTrial/UpgradeButton.vue'

export default defineComponent({
  components: { UpgradeButton },
  setup () {
    const { breakpoints } = useBreakpoints()
    
    const style = computed(() => {
      const image = require(`@/assets/img/people_3.jpg`)

      return breakpoints.smaller('md').value
        ? `background-image: linear-gradient(rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6)), url('${image}')`
        : `background-image: url(${image})`
    })

    return {
      style
    }
  }
})
