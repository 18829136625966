<template>
  <div
    class="bg-cover bg-no-repeat bg-top w-full rounded-md p-4 xl:py-24"
    :style="style"
  >
    <div
      class="flex flex-col justify-start items-center md:items-end space-y-10"
    >
      <small
        class="text-xs md:text-sm px-4 py-1 rounded-full bg-purple-100 text-purple-500"
      >
        <i class="fas fa-info-circle text-purple-500"></i>
        {{ $t('page.review.sentence.unavailable_resource') }}
      </small>

      <div class="text-xl lg:text-3xl text-center md:text-right font-bold text-purple-800"
        v-html="`${$t('page.bunner.subscription_offer.title-html').replace('/', '|')}`"
      />

      <div class="font-semibold w-full md:w-2/5 text-lg text-center md:text-right"
        v-html="$t('page.bunner.subscription_offer.text')"
      />

      <upgrade-button offer="paidContentOffer" :start-active="true">
        <button
          class="btn justify-center text-white bg-gradient-to-br from-purple-400 to-purple-700"
        >
          <i class="fas fa-crown"></i>
          {{ $t('action.upgrade') }}
        </button>
      </upgrade-button>
    </div>
  </div>
</template>

<script lang="ts">
import { useBreakpoints } from '@/composables/useBreakpoints'
import { computed, defineComponent } from 'vue'
import UpgradeButton from '../freeTrial/UpgradeButton.vue'

export default defineComponent({
  components: { UpgradeButton },
  setup () {
    const { breakpoints } = useBreakpoints()
    
    const style = computed(() => {
      const image = require(`@/assets/img/people_3.jpg`)

      return breakpoints.smaller('md').value
        ? `background-image: linear-gradient(rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6)), url('${image}')`
        : `background-image: url(${image})`
    })

    return {
      style
    }
  }
})
</script>

<style scoped>
.btn i.fa-crown {
  --tw-text-opacity: 1 !important;
  color: rgba(245, 158, 11, var(--tw-text-opacity)) !important;
}
</style>
